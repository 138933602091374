<script setup>
import { useRoute, useRouter } from 'vue-router'
import { ArrowTrendingUpIcon, ArrowsRightLeftIcon, BriefcaseIcon, ChartBarIcon, CodeBracketIcon } from '@heroicons/vue/24/solid'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import BaseTab from '@/components/base/BaseTab.vue'
import PageHeading from '@/components/common/PageHeading.vue'
import { useCampaignStore } from '@/stores/campaign.js'
import BaseSpinner from '@/components/base/BaseSpinner.vue'
import CampaignBreadcrumb from '@/components/partials/campaign/CampaignBreadcrumb.vue'
import Button from '@/components/ui/button/Button.vue'
import CampaignConvertToAdvancedForm from '@/components/partials/campaign/CampaignConvertToAdvancedForm.vue'
import CampaignConvertToBasicForm from '@/components/partials/campaign/CampaignConvertToBasicForm.vue'
import CampaignSetupParams from '@/components/partials/campaign/CampaignSetupParams.vue'
import CampaignTestButton from '@/components/partials/campaign/CampaignTestButton.vue'

const route = useRoute()
const router = useRouter()
const campaignStore = useCampaignStore()
const { t } = useI18n()

const tabs = computed(() => {
  const tab = [
    {
      name: t('common.campaign'),
      href: {
        name: 'Campaign',
        params: {
          id: route.params.id
        }
      },
      icon: BriefcaseIcon
    },
  ]

  if (campaignStore.campaign?.mode === 'advanced') {
    tab.push({
      name: t('common.integrations'),
      href: {
        name: 'CampaignIntegrations',
        params: {
          id: route.params.id
        }
      },
      icon: CodeBracketIcon
    })
  }

  tab.push(
    {
      name: t('common.requests'),
      href: {
        name: 'CampaignRequests',
        params: {
          id: route.params.id
        }
      },
      icon: ArrowTrendingUpIcon
    },
    {
      name: t('common.reports'),
      href: {
        name: 'CampaignReports',
        params: {
          id: route.params.id
        }
      },
      icon: ChartBarIcon
    }
  )

  return tab
})

const offerURL = computed(() => {
  if (!campaignStore.campaign?.domain || !campaignStore.campaign?.alias) {
    return
  }

  return `https://${campaignStore.campaign.domain}/${campaignStore.campaign.alias}`
})

// use toggle modal
const showAdvancedModalConverter = ref(false)
const showBasicModalConverter = ref(false)

function toggleModalConverter() {
  if (campaignStore.campaign?.mode === 'basic') {
    showAdvancedModalConverter.value = true
  } else {
    showBasicModalConverter.value = true
  }
}

// use fetch campaign
const isLoading = ref(false)
async function fetchCampaign(id) {
  isLoading.value = true
  try {
    await campaignStore.findById(id)
  } catch (error) {
    if (error.response?.status === 404) {
      await router.push({
        name: 'Error404'
      })
    }
  } finally {
    isLoading.value = false
  }
}

await fetchCampaign(route.params.id)
</script>

<template>
  <div class="space-y-5">
    <div class="space-y-2">
      <CampaignBreadcrumb />
      <div class="space-y-4">
        <div class="flex flex-col  justify-between sm:flex-row">
          <PageHeading :title="campaignStore.campaign?.name" />
          <div class="flex flex-wrap gap-2">
            <Button
              v-if="campaignStore.hasQueryParams"
              @click="campaignStore.toggleParamsConfig"
            >
              {{ t('common.setupParams') }}
            </Button>
            <Button
              variant="secondary"
              @click="toggleModalConverter"
            >
              {{ t('common.mode') }} {{ campaignStore.campaign?.mode === 'basic' ? t('common.advanced') : t('common.basic') }}
              <ArrowsRightLeftIcon class="ml-2 size-4" />
            </Button>
            <CampaignTestButton
              v-if="campaignStore.campaign?.mode === 'basic'"
              :campaign-id="Number(route.params.id)"
            />
          </div>
        </div>
        <BaseTab :tabs="tabs" />
      </div>
    </div>

    <router-view
      v-slot="{ Component }"
    >
      <Suspense
        :key="String(route.params.id)"
        timeout="0"
        suspensible
      >
        <div>
          <component
            :is="Component"
          />
        </div>
        <template #fallback>
          <div class="flex min-h-screen items-center justify-center">
            <BaseSpinner class="text-primary" />
          </div>
        </template>
      </Suspense>
    </router-view>

    <CampaignConvertToAdvancedForm
      v-model="showAdvancedModalConverter"
      :campaign="campaignStore.campaign"
      @convert="fetchCampaign(route.params.id)"
    />

    <CampaignConvertToBasicForm
      v-model="showBasicModalConverter"
      :campaign="campaignStore.campaign"
      @convert="fetchCampaign(route.params.id)"
    />
    <CampaignSetupParams
      v-model="campaignStore.showParamsConfig"
      :params="campaignStore.campaign?.filters?.query?.params"
      :url="offerURL"
      :network="campaignStore.getNetwork"
      :is-simple-mode="campaignStore.campaign?.mode === 'basic'"
    />
  </div>
</template>
