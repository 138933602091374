import * as Sentry from '@sentry/vue'
import { isLocal } from '@/helpers/is-local'

export function sentry(app, router) {
  if (isLocal) {
    console.log('Sentry disabled in local environment')
    return
  }

  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({
        router
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.5, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    beforeSend(event, hint) {
      // #https://github.com/getsentry/sentry-javascript/issues/2514
      if (hint.originalException === 'Timeout') {
        return null
      }
      return event
    },
    ignoreErrors: ['Object captured as exception']
  })
}
