import client from '../plugins/axios'

export const domainService = {
  resource: '/domains',
  findAll(params = {}) {
    return client.get(this.resource, {
      params
    })
  },
  create(domain) {
    return client.post(this.resource, domain)
  },
  delete(id) {
    return client.delete(`${this.resource}/${id}`)
  },
  verify(id) {
    return client.patch(`${this.resource}/${id}/verify`)
  },
  count() {
    return client.get(`${this.resource}/count`)
  },
  migrate(id) {
    return client.patch(`${this.resource}/${id}/migrate`)
  }
}
