<script setup>
import { onMounted, reactive, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { toast } from 'vue-sonner'
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'
import PhoneInput from '@/components/common/PhoneInput.vue'
import { authService } from '@/services/auth'
import { useAuthStore } from '@/stores/auth'
import Button from '@/components/ui/button/Button.vue'
import ErrorMessage from '@/components/ui/error-message/ErrorMessage.vue'
import Label from '@/components/ui/label/Label.vue'
import Input from '@/components/ui/input/Input.vue'
import Field from '@/components/ui/field/Field.vue'
import PasswordInput from '@/components/common/PasswordInput.vue'
import { rastrio } from '@/plugins/rastrio'
import { useGrecaptcha } from '@/composables/useGrecaptcha'
import PageHeading from '@/components/common/PageHeading.vue'

const { t } = useI18n()
useHead({
  title: t('pages.auth.sign_up.title')
})
const route = useRoute()
const router = useRouter()
const store = useAuthStore()

const form = reactive({
  name: '',
  email: '',
  password: '',
  phone: '',
})
const errors = ref({})

const isLoading = ref(false)

async function submitForm() {
  isLoading.value = true

  try {
    const token = await useGrecaptcha()
    await register({
      email: form.email,
      password: form.password,
      name: form.name,
      phone: form.phone,
      token
    })
  } catch (e) {
    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
    isLoading.value = false
  }
}

async function register({ name, email, password, phone, token }) {
  const formattedPhone = phone?.replace(/\D/g, '')
  const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  try {
    await authService.signUp({
      name,
      email,
      password,
      phone: formattedPhone,
      meta: route.query,
      timezone: clientTimeZone,
      language: window.navigator.language,
      token
    })

    store.token = '1'
    await store.checkAuth()

    toast.success(t('pages.auth.sign_up.toast.success.title'), {
      description: t('pages.auth.sign_up.toast.success.description'),
    })

    trackRegisterEvent(name, email, formattedPhone)

    const redirectTo = route.query.redirect_to
    if (redirectTo) {
      await router.push(redirectTo)
    } else {
      await router.push({
        name: 'CheckoutPlans',
        query: route.query
      })
    }
  } catch (e) {
    if (e.response?.status === 422) {
      errors.value = e.response?.data?.data
    }

    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isLoading.value = false
  }
}

/**
 * Track register event
 * @param {string} email
 * @param {string} name
 * @param {string} phone
 */
function trackRegisterEvent(name, email, phone) {
  try {
    rastrio().track('sign_up', {
      visitor: {
        email,
        name,
        phone,
      }
    })

    const firstName = name.split(' ')[0]
    const LastName = name.split(' ')[1]

    gtag('set', 'user_data', {
      email,
      phone_number: phone,
      address: {
        first_name: firstName,
        last_name: LastName,
      }
    })

    gtag('event', 'conversion', {
      send_to: 'AW-16571741400/Q63OCKC_pMEZENjpgt49'
    })
  } catch (error) {
    console.error('Error tracking register event', error)
  }
}
</script>

<template>
  <div>
    <div>
      <img
        class="h-12 w-auto dark:invert"
        src="../../assets/img/logo-black.svg"
        alt="Cloakup logo"
      >
      <PageHeading
        :title="$t('pages.auth.sign_up.title')"
        class="mt-6"
      />
      <p class="mt-2 text-sm ">
        <span class="text-muted-foreground">{{ $t('pages.auth.sign_up.already_member') }}</span>
        {{ " " }}
        <router-link
          :to="{ name: 'SignIn', query: route.query }"
          class="font-medium"
        >
          {{ $t("pages.auth.sign_up.sign_in") }}
        </router-link>
      </p>
    </div>
    <div class="mt-8">
      <form @submit.prevent="submitForm">
        <fieldset
          :disabled="isLoading"
          class="grid grid-cols-1 gap-4 sm:grid-cols-3 md:grid-cols-6"
        >
          <div class="col-span-full">
            <Field>
              <Label
                for="name"
                required
              >
                {{ $t("common.name") }}
              </Label>
              <Input
                id="name"
                v-model="form.name"
                name="name"
                type="text"
                autocomplete="name"
                required
              />
              <ErrorMessage :message="errors?.name" />
            </Field>
          </div>
          <div class="col-span-full">
            <Field>
              <Label
                for="email"
                required
              >
                {{ $t("common.email") }}
              </Label>
              <Input
                id="email"
                v-model="form.email"
                name="email"
                type="email"
                autocomplete="email"
                required
              />
              <ErrorMessage :message="errors?.email" />
            </Field>
          </div>
          <div class="col-span-full">
            <Field>
              <Label
                for="phone"
                required
              >
                {{ $t("whatsapp") }}
              </Label>
              <PhoneInput
                id="phone"
                v-model="form.phone"
                type="tel"
                autocomplete="tel"
                inputmode="numeric"
                required
              />
              <ErrorMessage :message="errors?.phone" />
            </Field>
          </div>
          <div class="col-span-full">
            <Field>
              <Label
                required
                for="password"
              >
                {{ $t("common.password") }}
              </Label>
              <PasswordInput
                id="password"
                v-model="form.password"
                name="password"
                autocomplete="new-password"
                required
              />
              <ErrorMessage :message="errors?.password" />
            </Field>
          </div>
          <div class="col-span-full">
            <Button
              type="submit"
              class="w-full"
              :loading="isLoading"
            >
              {{ $t("pages.auth.sign_up.title") }}
            </Button>
          </div>
          <div class="col-span-full">
            <p class="text-sm text-muted-foreground">
              {{ $t("pages.auth.sign_up.by_signing_up") }}
              <a
                href="https://cloakup.me/terms"
                class="text-blue-500"
                target="_blank"
              >{{ $t("pages.auth.sign_up.terms") }}
              </a>
              {{ $t("common.and") }}
              <a
                href="https://cloakup.me/privacy"
                class="text-blue-500"
                target="_blank"
              >{{ $t("pages.auth.sign_up.privacy") }}
              </a>
            </p>
          </div>
        </fieldset>
      </form>
    </div>
  </div>
</template>
