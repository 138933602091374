import router from '@/router/index'
import { crisp } from '@/plugins/crisp.js'
import Maska from 'maska'
import { createPinia } from 'pinia'
import VueVectorMap from 'vuevectormap'
import 'vuevectormap/src/scss/vuevectormap.scss'
import 'jsvectormap/dist/maps/world'
import { createHead } from '@unhead/vue'
import { sentry } from './sentry'
import { clarity } from './clarity'
import i18n from './i18n'
import { gtag } from './googletags'
import { activeCampaign } from './active-campaign'
import { rastrio } from './rastrio'
import { useAuthStore } from '@/stores/auth'

async function setupApp() {
  const { checkAuth } = useAuthStore()
  await checkAuth()
}

function setupScripts() {
  rastrio().setup()
  crisp().setup()
  clarity().setup()
  gtag().setup()
  activeCampaign().setup()
}

export async function setupPlugins(app) {
  const pinia = createPinia()
  app.use(pinia)
  app.use(Maska)
  app.use(i18n)
  app.use(VueVectorMap, {})
  sentry(app, router)

  const head = createHead()
  app.use(head)

  // scripts
  setupScripts()

  await setupApp()

  app.use(router)

  await router.isReady()
}
