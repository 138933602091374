export function rastrio() {
    const projectId = import.meta.env.VITE_RASTRIO_PROJECT_ID
    const cdnUrl = import.meta.env.VITE_RASTRIO_CDN_URL
  
    function setup() {
      if (!projectId || !cdnUrl) {
        return
      }
  
      const scriptContent = `!!function(r,a,s,t,i,o){
r[i]=r[i]||function(){(r[i].q=r[i].q||[]).push(arguments)};
o=a.createElement(s),s=a.getElementsByTagName(s)[0];
o.async=!0;o.src=t;s.parentNode.insertBefore(o,s);}(window,document,
'script','${cdnUrl}','rastrio');
rastrio('init','${projectId}');`
  
      const script = document.createElement('script')
      script.innerHTML = scriptContent
      document.head.appendChild(script)
    }
  
  /**
   * @param {string} event
   * @param {object} data
   * @param {function} callback
   */
    function track(event, data, callback) {
      if (!projectId || !cdnUrl) {
        return
      }

      window.rastrio('track', event, data, callback)
    }


    return {
      setup,
      track
    }
  }
  