<script setup lang="ts">
import { CaretSortIcon } from '@radix-icons/vue'
import { ref } from 'vue'
import { EllipsisVerticalIcon, InformationCircleIcon, TrashIcon } from '@heroicons/vue/24/solid'
import { GlobeAltIcon } from '@heroicons/vue/24/outline'
import { toast } from 'vue-sonner'
import { useI18n } from 'vue-i18n'
import { useMediaQuery } from '@vueuse/core'
import DomainStatusBadge from './DomainStatusBadge.vue'
import DomainCnameTable from './DomainCnameTable.vue'
import DomainDeleteModal from './DomainDeleteModal.vue'
import Button from '@/components/ui/button/Button.vue'
import { Card, CardContent, CardFooter, } from '@/components/ui/card'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/components/ui/collapsible'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { domainService } from '@/services/domain'
import Badge from '@/components/ui/badge/Badge.vue'

const props = defineProps({
  domain: {
    type: Object,
    required: true,
  },
  showMigration: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['verified', 'deleted', 'migrated'])
const { t } = useI18n()
const isDesktop = useMediaQuery('(min-width: 920px)')
const cloakupDNS = import.meta.env.VITE_DOMAINS_CLOAKUP

const isVerifying = ref(false)
const showDeleteModal = ref(false)
const showConfig = ref(!props.domain.migrated || !props.domain.verified)

/**
 * @param {string} id
 */
async function verifyDomain(id) {
  isVerifying.value = true

  try {
    await domainService.verify(id)

    toast.success(t('components.verify_domain_modal.toast.success.title'), {
      description: t('components.verify_domain_modal.toast.success.description'),
    })

    emit('verified')
  } catch (e) {
    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isVerifying.value = false
  }
}

const isMigrating = ref(false)
/**
 * @param {string} id
 */

async function migrateDomain(id) {
  isMigrating.value = true

  try {
    await domainService.migrate(id)

    toast.success(t('migrate_domain_success_title'), {
      description: t('migrate_domain_success_description'),
    })

    emit('migrated')
  } catch (e) {
    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isMigrating.value = false
  }
}
</script>

<template>
  <Card>
    <Collapsible
      v-model:open="showConfig"
    >
      <CardContent class="p-4 sm:p-5">
        <div class="flex w-full  items-center justify-between gap-3 ">
          <div class="flex min-w-0 items-center gap-4">
            <div class="hidden rounded-full border border-border sm:block">
              <div class="rounded-full border border-border p-1 md:p-2">
                <GlobeAltIcon class="size-5 text-foreground" />
              </div>
            </div>
            <div class="overflow-hidden">
              <div class="flex items-center gap-1.5 sm:gap-2.5">
                <div v-if="!isDesktop">
                  <!-- Verified dots -->
                  <div
                    v-if="domain.verified"
                    class="block size-2 rounded-full bg-success sm:hidden"
                  />
                  <div
                    v-else-if="showMigration && domain.migrated"
                    class="block size-2 rounded-full bg-warning sm:hidden"
                  />
                  <div
                    v-else
                    class="block size-2 rounded-full bg-destructive sm:hidden"
                  />
                </div>
                <a
                  :href="`https://${domain.name}`"
                  target="_blank"
                  rel="noreferrer"
                  class="truncate text-sm font-medium"

                  :title="domain.name"
                >{{ domain.name }}</a>
                <DomainStatusBadge
                  v-if="isDesktop"
                  :verified="domain.verified"
                  class="hidden sm:block"
                />
                <Badge
                  v-if="showMigration && !domain.migrated"
                  variant="warning"
                  class="hidden sm:block"
                >
                  {{ $t('migration_pending') }}
                </Badge>
              </div>
            </div>
          </div>

          <div class="flex gap-2">
            <div
              v-if="isDesktop"
              class="flex items-center"
            >
              <Button
                v-if="!domain.verified"
                variant="secondary"
                :disabled="isVerifying"
                :loading="isVerifying"
                class="mr-2"
                @click="verifyDomain(domain.id)"
              >
                {{ $t('components.verify_domain_modal.button') }}
              </Button>

              <Button
                variant="ghost"
                @click="showDeleteModal = true"
              >
                <TrashIcon class="size-5" />
              </Button>
            </div>

            <CollapsibleTrigger as-child>
              <Button
                variant="ghost"
              >
                <CaretSortIcon class="size-5" />
                <span class="sr-only">Toggle</span>
              </Button>
            </CollapsibleTrigger>

            <div v-if="!isDesktop">
              <DropdownMenu>
                <DropdownMenuTrigger as-child>
                  <Button variant="ghost">
                    <EllipsisVerticalIcon class="size-5" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuGroup>
                    <DropdownMenuItem
                      v-if="!domain.verified"
                      @click="verifyDomain(domain.id)"
                    >
                      <span>
                        {{ $t('components.verify_domain_modal.button') }}
                      </span>
                    </DropdownMenuItem>
                    <DropdownMenuItem @click="showDeleteModal = true">
                      <span>
                        {{ $t('common.delete') }}
                      </span>
                    </DropdownMenuItem>
                  </DropdownMenuGroup>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
        </div>
      </CardContent>

      <CollapsibleContent class="w-full space-y-2 border-t-2 border-muted pt-4">
        <div
          v-if="showMigration && !domain.migrated"
          class="p-4"
        >
          <div
            class="rounded-lg border border-warning bg-background p-4 shadow-lg shadow-warning/10"
          >
            <div class="flex gap-2">
              <div class="flex grow gap-3">
                <InformationCircleIcon
                  class="mt-0.5 size-5 shrink-0 text-amber-500"
                  stroke-width="2"
                  aria-hidden="true"
                />
                <div class="flex grow flex-col gap-3">
                  <div class="space-y-1">
                    <p class="text-sm font-medium">
                      {{ $t('migration_pending') }}
                    </p>
                    <p
                      class="text-sm text-muted-foreground"
                      v-html="$t('migrate_domain_description', {
                        cloakupDNS,
                      })"
                    />
                  </div>
                  <div>
                    <Button

                      size="sm"
                      :disabled="isMigrating"
                      :loading="isMigrating"
                      @click="migrateDomain(domain.id)"
                    >
                      {{ $t('migrate_domain_button') }}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CardFooter class="flex flex-col gap-4">
          <Tabs
            default-value="cname"

            class="w-full"
          >
            <TabsList class="w-full justify-start rounded-none  border-b-2 border-b-muted bg-background ">
              <TabsTrigger
                value="cname"
                class="block rounded-none border-b-2 border-b-primary bg-background p-2 text-primary data-[state=active]:shadow-none"
              >
                CNAME
              </TabsTrigger>
            </TabsList>
            <TabsContent value="cname">
              <div class="flex flex-col gap-4 pt-4 text-foreground">
                <span class="text-sm">{{ $t('components.domain_card.to_setup_your_subdomain') }} <span
                  class="inline-block rounded-md bg-primary-foreground px-1 py-0.5 font-mono"
                >{{ domain.name }}</span>,

                  {{ $t('components.domain_card.add_the_following_cname_record') }}:
                </span>

                <DomainCnameTable
                  :name="domain.name"
                  :cname="cloakupDNS"
                />
              </div>
              <div class="mt-5">
                <p class="text-sm text-muted-foreground">
                  {{ $t('components.domain_card.note_start') }} <span
                    class="inline-block rounded-md bg-primary-foreground px-1 py-0.5 font-mono"
                  >86400</span>
                  {{ $t('components.domain_card.note_end') }}
                </p>
              </div>
            </TabsContent>
          </Tabs>
        </CardFooter>
      </CollapsibleContent>
    </Collapsible>
  </Card>
  <DomainDeleteModal
    :id="domain.id"
    v-model="showDeleteModal"
    @deleted="emit('deleted')"
  />
</template>
