<script setup>
import { ComboboxAnchor, ComboboxContent, ComboboxInput, ComboboxPortal, ComboboxRoot } from 'radix-vue'
import { computed, ref } from 'vue'
import Button from './ui/button/Button.vue'
import { CommandEmpty, CommandGroup, CommandItem, CommandList } from '@/components/ui/command'
import { TagsInput, TagsInputInput, TagsInputItem, TagsInputItemDelete, TagsInputItemText } from '@/components/ui/tags-input'
import TextAreaMultipleModal from '@/components/partials/campaign/TextAreaMultipleModal.vue'

const props = defineProps({
  options: {
    type: Array,
    required: false,
    default: () => []
  },
  placeholder: {
    type: String,
    required: false,
    default: 'Search...'
  }
})

const modelValue = defineModel({
  required: true
})

const open = ref(false)
const searchTerm = ref('')

const filteredOptions = computed(() => props.options?.filter(i => !modelValue.value.includes(i.label)))

function addMultiple(items) {
  modelValue.value = [...modelValue.value, ...items]
}

function removeAll() {
  modelValue.value = []
}
</script>

<template>
  <div class="flex items-center  gap-2">
    <TagsInput
      v-model="modelValue"
      class="w-full gap-0 px-0"
      :add-on-paste="!props.options.length"
      :add-on-tab="!props.options.length"
      :add-on-blur="!props.options.length"
    >
      <div class="flex flex-wrap items-center gap-2 px-2">
        <TagsInputItem
          v-for="item in modelValue"
          :key="item"
          :value="item"
        >
          <TagsInputItemText />
          <TagsInputItemDelete />
        </TagsInputItem>
      </div>

      <ComboboxRoot
        v-model="modelValue"
        v-model:open="open"
        v-model:search-term="searchTerm"
        class="w-full"
      >
        <ComboboxAnchor as-child>
          <ComboboxInput
            :placeholder="placeholder"
            as-child
          >
            <TagsInputInput
              class="w-full border-none bg-transparent px-2 placeholder:text-muted-foreground focus:border-0 focus:outline-none focus:ring-0"
              :class="modelValue.length > 0 ? 'mt-2' : ''"
              @keydown.enter.prevent
            />
          </ComboboxInput>
        </ComboboxAnchor>

        <ComboboxPortal v-if="props.options?.length">
          <ComboboxContent>
            <CommandList
              position="popper"
              class="mt-2 w-[--radix-popper-anchor-width] rounded-md border bg-popover text-popover-foreground shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2"
            >
              <CommandEmpty />
              <CommandGroup>
                <CommandItem
                  v-for="option in filteredOptions"
                  :key="option.value"
                  :value="option.label"
                  @select.prevent="(ev) => {
                    if (typeof ev.detail.value === 'string') {
                      searchTerm = ''
                      modelValue.push(ev.detail.value)
                    }

                    if (filteredOptions.length === 0) {
                      open = false
                    }
                  }"
                >
                  {{ option.label }}
                </CommandItem>
              </CommandGroup>
            </CommandList>
          </ComboboxContent>
        </ComboboxPortal>
      </ComboboxRoot>
    </TagsInput>
    <div class="flex  gap-2">
      <Button
        type="button"
        @click="removeAll"
      >
        {{ $t('common.clear') }}
      </Button>
      <TextAreaMultipleModal
        @add="addMultiple"
      />
    </div>
  </div>
</template>
