import { ComputerDesktopIcon, DevicePhoneMobileIcon, DeviceTabletIcon, FireIcon, GlobeAltIcon, PhoneIcon } from '@heroicons/vue/24/solid'
import AndroidIcon from '@/components/icons/AndroidIcon.vue'
import ChromeIcon from '@/components/icons/browsers/ChromeIcon.vue'
import MacOs from '@/components/icons/MacOs.vue'
import SafariIcon from '@/components/icons/browsers/SafariIcon.vue'
import SamsungBrowserIcon from '@/components/icons/browsers/SamsungBrowserIcon.vue'
import FacebookIcon from '@/components/icons/browsers/FacebookIcon.vue'
import InstagramIcon from '@/components/icons/browsers/InstagramIcon.vue'
import MicrosoftEdgeIcon from '@/components/icons/browsers/MicrosoftEdgeIcon.vue'
import WindowsIcon from '@/components/icons/WindowsIcon.vue'
import GoogleSearchIcon from '@/components/icons/browsers/GoogleSearchIcon.vue'
import LinuxIcon from '@/components/icons/LinuxIcon.vue'
import FirefoxIcon from '@/components/icons/browsers/FirefoxIcon.vue'
import UbuntuIcon from '@/components/icons/UbuntuIcon.vue'
import BaiduIcon from '@/components/icons/browsers/BaiduIcon.vue'
import OperaIcon from '@/components/icons/browsers/OperaIcon.vue'
import InternetExplorerIcon from '@/components/icons/browsers/InternetExplorer.vue'
import EdgeIcon from '@/components/icons/browsers/Edge.vue'

export function getOsIcon(os) {
  const icons = {
    'android': AndroidIcon,
    'mac': MacOs,
    'ios': MacOs,
    'windows': WindowsIcon,
    'gnu/linux': LinuxIcon,
    'ubuntu': UbuntuIcon,
  }

  return icons[os] ?? null
}

export function getBrowserIcon(browser) {
  const icons = {
    'chrome': ChromeIcon,
    'chrome mobile': ChromeIcon,
    'chrome webview': ChromeIcon,
    'chrome mobile ios': ChromeIcon,
    'mobile safari': SafariIcon,
    'safari': SafariIcon,
    'samsung browser': SamsungBrowserIcon,
    'instagram app': InstagramIcon,
    'facebook': FacebookIcon,
    'microsoft edge': MicrosoftEdgeIcon,
    'google search app': GoogleSearchIcon,
    'firefox': FirefoxIcon,
    'baidu spark': BaiduIcon,
    'opera': OperaIcon,
    'internet explorer': InternetExplorerIcon,
    'edge': EdgeIcon,

  }

  return icons[browser] ?? GlobeAltIcon
}

export function getDeviceIcon(device) {
  const icons = {
    desktop: ComputerDesktopIcon,
    mobile: PhoneIcon,
    tablet: DeviceTabletIcon,
    smartphone: DevicePhoneMobileIcon,
  }

  return icons[device] ?? null
}
