<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useColorMode } from '@vueuse/core'
import { ArrowLeftOnRectangleIcon, CheckIcon, ChevronUpDownIcon, ComputerDesktopIcon, CreditCardIcon, LifebuoyIcon, MegaphoneIcon, MoonIcon, SunIcon, SwatchIcon, UserGroupIcon } from '@heroicons/vue/24/solid'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { useUserStore } from '@/stores/user'
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuLabel, DropdownMenuPortal, DropdownMenuRadioGroup, DropdownMenuRadioItem, DropdownMenuSeparator, DropdownMenuSub, DropdownMenuSubContent, DropdownMenuSubTrigger, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'
import { useAuthStore } from '@/stores/auth'

const userStore = useUserStore()
const authStore = useAuthStore()
const router = useRouter()
const mode = useColorMode()

const isQuitting = ref(false)

async function logout() {
  try {
    isQuitting.value = true
    await authStore.signOut()
    await router.push({
      name: 'SignIn'
    })
  } catch (error) {
    console.log('error', error)
  } finally {
    isQuitting.value = false
  }
}

function changeTheme(theme) {
  mode.value = theme
}
</script>

<template>
  <DropdownMenu>
    <DropdownMenuTrigger class="w-full">
      <div
        class="flex w-full items-center justify-between gap-x-4 px-4 py-3  leading-6 hover:bg-primary/10"
      >
        <Avatar class="size-8 rounded-lg">
          <AvatarImage
            :src="userStore.user?.name"
            :alt="userStore.user?.name"
          />
          <AvatarFallback class="rounded-lg">
            {{ userStore.user?.name[0] }}
          </AvatarFallback>
        </Avatar>
        <div class="grid flex-1 text-left text-sm leading-tight">
          <span class="truncate font-semibold">{{ userStore.user?.name }}</span>
          <span class="truncate text-xs text-muted-foreground">{{ userStore.user?.email }}</span>
        </div>

        <ChevronUpDownIcon class="size-5 text-muted-foreground" />
      </div>
    </DropdownMenuTrigger>
    <DropdownMenuContent class="w-56">
      <DropdownMenuLabel>
        <div class="flex items-center gap-2 px-1 py-1.5 text-left text-sm">
          <Avatar class="size-8 rounded-lg">
            <AvatarImage
              :src="userStore.user?.name"
              :alt="userStore.user?.name"
            />
            <AvatarFallback class="rounded-lg">
              {{ userStore.user?.name[0] }}
            </AvatarFallback>
          </Avatar>
          <div class="grid flex-1 text-left text-sm leading-tight">
            <span class="truncate font-semibold">
              {{ userStore.user?.name }}
            </span>
            <span class="truncate text-xs text-muted-foreground">
              {{ userStore.user?.email }}
            </span>
          </div>
        </div>
      </DropdownMenuLabel>
      <DropdownMenuSeparator />
      <DropdownMenuGroup>
        <DropdownMenuItem as-child>
          <router-link
            to="/settings/profile"
          >
            <UserGroupIcon class="mr-2 size-4" />
            <span> {{ $t('components.profile.profile') }}</span>
          </router-link>
        </DropdownMenuItem>
        <DropdownMenuItem as-child>
          <router-link to="/settings/subscriptions">
            <CreditCardIcon class="mr-2 size-4" />
            <span> {{ $t('components.profile.subscriptions') }}</span>
          </router-link>
        </DropdownMenuItem>

        <DropdownMenuItem as-child>
          <router-link
            :to="{ name: 'dashboard.referral.index' }"
          >
            <MegaphoneIcon class="mr-2 size-4" />
            <span> {{ $t('components.profile.referral') }}</span>
          </router-link>
        </DropdownMenuItem>

        <DropdownMenuSub>
          <DropdownMenuSubTrigger>
            <SwatchIcon class="mr-2 size-4" />
            <span>{{ $t('theme') }}</span>
          </DropdownMenuSubTrigger>
          <DropdownMenuPortal>
            <DropdownMenuSubContent>
              <DropdownMenuRadioGroup v-model="mode">
                <DropdownMenuRadioItem
                  value="system"
                >
                  <ComputerDesktopIcon class="mr-2 size-4" />
                  <span>
                    {{ $t('system') }}
                  </span>
                </DropdownMenuRadioItem>

                <DropdownMenuRadioItem
                  value="dark"
                >
                  <MoonIcon class="mr-2 size-4" />

                  <span>
                    {{ $t('dark') }}
                  </span>
                </DropdownMenuRadioItem>

                <DropdownMenuRadioItem
                  value="light"
                >
                  <SunIcon class="mr-2 size-4" />
                  <span>
                    {{ $t('light') }}
                  </span>
                </DropdownMenuRadioItem>
              </DropdownMenuRadioGroup>
            </DropdownMenuSubContent>
          </DropdownMenuPortal>
        </DropdownMenuSub>
      </DropdownMenuGroup>
      <DropdownMenuSeparator />

      <DropdownMenuItem as-child>
        <a
          href="http://help.cloakup.me/"
          target="_blank"
        >
          <LifebuoyIcon class="mr-2 size-4" />
          {{ $t('components.profile.help') }}
        </a>
      </DropdownMenuItem>

      <DropdownMenuSeparator />
      <DropdownMenuItem @click="logout">
        <ArrowLeftOnRectangleIcon class="mr-2 size-4" />
        {{ $t('components.profile.logout') }}
      </DropdownMenuItem>
    </DropdownMenuContent>
  </DropdownMenu>
</template>
