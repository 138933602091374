<script setup >
import { ref, watch } from 'vue'
import { toast } from 'vue-sonner'
import { Switch } from '@/components/ui/switch'
import { campaignService } from '@/services/campaign'
import { Label } from '@/components/ui/label'
import BaseSpinner from '@/components/base/BaseSpinner.vue'
import { useCampaignStore } from '@/stores/campaign'

const props = defineProps({
  campaignId: {
    type: Number,
    required: true,
  }
})
const campaignStore = useCampaignStore()

const isLoading = ref(false)
const testMode = ref(false)

function onChange(value) {
  testMode.value = value
  if (value) {
    addIpToWhitelist()
  } else {
    clearWhiteList()
  }
}

async function clearWhiteList() {
  isLoading.value = true
  try {
    await campaignService.clearWhitelist(props.campaignId)
  } catch (e) {
    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isLoading.value = false
  }
}

async function addIpToWhitelist() {
  isLoading.value = true
  try {
    await campaignService.whitelist(props.campaignId)
  } catch (e) {
    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isLoading.value = false
  }
}

watch(() => campaignStore.campaign?.is_test_mode, () => {
  testMode.value = campaignStore.campaign?.is_test_mode
}, {
  immediate: true
})
</script>

<template>
  <div class="flex items-center space-x-2 rounded-md border px-4 py-2">
    <BaseSpinner v-if="isLoading" />
    <Switch
      id="test-mode"
      v-model:checked="testMode"
      @update:checked="onChange"
    />
    <Label for="test-mode">
      {{ $t('test_action') }}
    </Label>
  </div>
</template>
